import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { AvailableColumn, ValidationListColumn } from '@app/common/models';
import { Store, select } from '@ngrx/store';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ColumnTypes, NumberRangeValue, NumberValue, TextValue, DateRangeValue, BoolValue, ListValue, DateValue, DynListValue as DynamicListValue, DynListOption, ItemValueModel, ItemValueListTypeTable, EANValue } from '@app/common/models/columnBase.model';
import { filter, Observable, of, take } from 'rxjs';
import { ISetting, ISettingChildrenValue, ISettingValue, SettingValueEnum, SettingValueTypeEnum } from '@app/common/models/setting.models';


import { CommonCheckService } from '@app/common/services/common.validation.service';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { GetSettingsChildrenAction, GetSettingsStateAction, selectSettingChildrenValues, selectSettingValues } from '@app/common-data-module/store';
import { GetAllAvailableColumnsAction, GetStaticListValuesAction, selectAllAvailableColumns, selectStaticUsedlistValues, selectValidationColumnsForList, UpdateStaticListValuesAction } from '../store';

@Component({
  selector: 'app-editvalidationcolumn-view',
  templateUrl: 'editValidationColumn.dialog.component.html',
  styleUrls: ['./dialogs.component.scss'],
})

export class EditValidationColumnDialogComponent implements OnInit {

  validationColumnToView: ValidationListColumn;
  numberRangeValue: NumberRangeValue = { numberrangestart: 0, numberrangeend: 0 };
  dateRangeValue: DateRangeValue = { daterangestart: new Date, daterangeend: new Date };
  numberValue: NumberValue = { maxdecimals: 0, maxdigits: 0, isnumberonly: false, requiredcolumnvalue: null };
  textValue: TextValue = { maxlength: 120, validregex: null, minlength: 0 };
  eanValue: EANValue = { value: undefined };
  listValue: ListValue = { valuelist: [] };
  checkBoxValue: BoolValue = { value: null };
  dateValue: DateValue = { value: null };
  dynListOptions: DynamicListValue[] = [];
  dynmlistValue: DynamicListValue = null
  moebeldynmlistValue: DynamicListValue = null;


  colTypeOptions: { label: string, value: number }[] = [];
  colEnum: any;
  datePickerOpen = false;

  settingList: WritableSignal<ISetting[]> = signal([]);
  settingValueList: WritableSignal<ISettingValue[]> = signal([]);
  selecteddbMapping: WritableSignal<ISettingValue> = signal(null);
  selectedChildren: WritableSignal<{ [key: string]: ISettingChildrenValue[] }> = signal({});
  hint: WritableSignal<string> = signal(null);

  selectStaticUsedlistValues$: Observable<{ [key: number]: ItemValueModel[] }> = of({});
  selectAllAvailableColumns$: Observable<AvailableColumn[]>;
  fndAvailablecol: AvailableColumn;
  validationListColumns$: Observable<ValidationListColumn[]>;

  referenceColumnValuesDynListOptions: WritableSignal<ItemValueModel[]> = signal([]);
  referenceColumnValueDynList: string;

  referenceColumnValueNumberListOptions: WritableSignal<ItemValueModel[]> = signal([]);
  allColumns: ValidationListColumn[];



  constructor(
    private store: Store<any>,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private validater: CommonCheckService
  ) {

    this.selectStaticUsedlistValues$ = this.store.pipe(select(selectStaticUsedlistValues));

    this.selectAllAvailableColumns$ = this.store.pipe(select(selectAllAvailableColumns))

    this.validationListColumns$ = this.store.pipe(select(selectValidationColumnsForList));


  }

  ngOnInit() {

    const keys1 = Object.keys(DynListOption).filter(key => !isNaN(DynListOption[key]));
    keys1.forEach(x => this.dynListOptions.push({ label: x, value: DynListOption[x] }));
    this.dynmlistValue = this.dynListOptions.find(f => f.value == this.config.data.validationColumnToView.listOptions);

    //nur Möbel Options zur Zeit, keine Auswahl
    this.moebeldynmlistValue = this.dynListOptions.find(f => f.value == DynListOption.MöbeldesSortimentsundModul);

    this.validationColumnToView = this.config.data.validationColumnToView;

    this.allColumns = this.config.data.allColumns;
    this.referenceColumnValueNumberListOptions.set(this.allColumns.filter(f=> f.colName != this.validationColumnToView.colName
      && f.dbMapping
      && (f.colType == ColumnTypes.Number || f.colType == ColumnTypes.DynamicList )
     ).map(x => <ItemValueModel>{ label: x.colName, value: x.dbMapping }));

     this.referenceColumnValuesDynListOptions.set(this.allColumns.filter(f=> f.colName != this.validationColumnToView.colName
      && f.dbMapping
      && (f.colType == ColumnTypes.Number || f.colType == ColumnTypes.DynamicList )
     ).map(x => <ItemValueModel>{ label: x.colName, value: x.dbMapping }));

    if (this.validationColumnToView.colType == ColumnTypes.List) {

      this.store.dispatch(new GetStaticListValuesAction({ columnlistid: this.validationColumnToView.id, listtabletype: ItemValueListTypeTable.LISTCOLUMNS }));

    }

    if (this.validationColumnToView.validationRule) {

      switch (this.validationColumnToView.colType) {

        case ColumnTypes.EAN:
          this.eanValue = JSON.parse(this.validationColumnToView.validationRule);

          break;
        case (ColumnTypes.Text):
          this.textValue = JSON.parse(this.validationColumnToView.validationRule);
          this.textValue.maxlength = this.validationColumnToView.maxLength;
          this.textValue.minlength = this.validationColumnToView.minLength ?? 0;
          this.textValue.validregex = this.validationColumnToView.validRegEx;

          break;
        case (ColumnTypes.DateRange):
          //not used


          break;
        case (ColumnTypes.NumberRange):
          //not used

          break;
        case (ColumnTypes.List):
          this.listValue = JSON.parse(this.validationColumnToView.validationRule);
          this.selectStaticUsedlistValues$.subscribe(data => {
            if (data && this.validationColumnToView.id > 0 && data[this.validationColumnToView.id]) {
              this.listValue.valuelist = data[this.validationColumnToView.id].map(x => <any>{ item: x.value });;
            }
          })

          break;
        case (ColumnTypes.Number):
          this.numberValue = JSON.parse(this.validationColumnToView.validationRule);
          this.numberValue.isnumberonly = this.validationColumnToView.isNumberOnly == 1;
          this.numberValue.maxdigits = this.validationColumnToView.maxDigits;
          this.numberValue.maxdecimals = this.validationColumnToView.maxDecimals;
          this.numberValue.requiredcolumnvalue = this.validationColumnToView.requiredColumnValue;
          break;
        case (ColumnTypes.CheckBox):
          this.checkBoxValue = JSON.parse(this.validationColumnToView.validationRule);
          break;
        case (ColumnTypes.Date):
          this.dateValue = JSON.parse(this.validationColumnToView.validationRule);
          break;
        case (ColumnTypes.DynamicList):
          this.dynmlistValue = { ...this.dynListOptions.find(f => f.value == this.validationColumnToView.listOptions) };
          this.referenceColumnValueDynList = this.validationColumnToView.requiredColumnValue;
          break;
      }
    }


    const keys = Object.keys(ColumnTypes).filter(key => !isNaN(ColumnTypes[key]));
    keys.forEach(x => this.colTypeOptions.push({ label: x, value: ColumnTypes[x] }));

    this.colEnum = ColumnTypes;



    this.store.dispatch(new GetSettingsStateAction({ settingid: SettingValueEnum.DBMapping, onlyActive: true }));

    this.store.pipe(select(selectSettingValues)).pipe(filter(f => f && f.length > 0), take(1)).subscribe(d => {
      if (this.validationColumnToView.dbMapping && !d.find(f => f.settingValue == this.validationColumnToView.dbMapping)) {
        d = this.validater.getClone(d);
        var current = <ISettingValue>{ id: 0, setting_Id: SettingValueEnum.DBMapping, settingValue: this.validationColumnToView.dbMapping, isActive: 1 };
        d.unshift(current);
        this.hint.set('Die Datenbankzuordnung wurde nicht gefunden. ');
      }

      this.settingValueList.set(d);
      this.selecteddbMapping.set(d.find(f => f.settingValue == this.validationColumnToView.dbMapping));
      if (this.selecteddbMapping()) {
        this.changeDbMapping({ value: this.selecteddbMapping(), originalEvent: null }, true);
      }

    }
    )

  }


  changeListenTyp(e)
  {
    if ( e.value == ColumnTypes.DynamicList) {
      this.dynmlistValue = this.moebeldynmlistValue;
    } else
    {
      this.dynmlistValue = null;
    }
  }

  changeDynList(e) {
    this.dynmlistValue = e.value;
  }

  clearDbMapping() {
    this.validationColumnToView = this.validater.getClone(this.validationColumnToView);
    this.validationColumnToView.colType = ColumnTypes.Text;
    this.validationColumnToView.dbMapping = null;
    this.validationColumnToView.bubMapping = null;
  }

  changeDbMapping(e: DropdownChangeEvent, init: boolean = false) {

    this.hint.set(null);
    this.validationColumnToView = this.validater.getClone(this.validationColumnToView);

    if (!init) {
      this.validationColumnToView.dbMapping = e.value.settingValue;
      this.validationColumnToView.bubMapping = null;
    }
    if (this.selectedChildren()[e.value.id] == undefined || this.selectedChildren()[e.value.id].length == 0) {
      this.store.dispatch(new GetSettingsChildrenAction({ settingvalueId: e.value.id }));
    }
    this.store.pipe(select(selectSettingChildrenValues)).subscribe(data => {
      var elements = data[e.value.id];
      if (elements instanceof Array) {

        this.validationColumnToView = this.validater.getClone(this.validationColumnToView);
        var coltype = elements.find(x => x.name == 'columntype');
        if (coltype && coltype.settingValue) {
          var newtype =  Number(coltype.settingValue) as ColumnTypes;
          if (init && this.validationColumnToView.colType != newtype && init)
          {
            this.hint.set('Spaltentyp wurde geändert von ' + ColumnTypes[this.validationColumnToView.colType] + ' zu ' + ColumnTypes[newtype]);
          }
          this.validationColumnToView.colType = newtype;

          this.selectAllAvailableColumns$.pipe(take(1)).subscribe(allcols => {
            this.fndAvailablecol = allcols.find(x => x.dbMapping == e.value.settingValue && x.colType == this.validationColumnToView.colType);
            if (this.validationColumnToView.id == 0 && this.fndAvailablecol && this.validationColumnToView.colType == ColumnTypes.List) {
              this.store.dispatch(new GetStaticListValuesAction({ columnlistid: this.fndAvailablecol.id, listtabletype: ItemValueListTypeTable.AVAILABLECOLUMNS }));
              this.selectStaticUsedlistValues$.subscribe(data => {
                if (data && this.validationColumnToView.id == 0 && data[this.fndAvailablecol.id]) {
                  this.listValue.valuelist = data[this.fndAvailablecol.id].map(x => <any>{ item: x.value });;
                }
              })
            }
          })
        }
        var bub = elements.find(x => x.name == 'bubmapping');
        if (bub && bub.settingValue) {
          if (init && this.validationColumnToView.bubMapping != bub.settingValue) {
            this.hint.set('BubMapping wurde geändert');
          }
          if (this.validationColumnToView.dbMapping == e.value.settingValue) {
            this.validationColumnToView.bubMapping = bub.settingValue;
          }
        }
        var desc = elements.find(x => x.name == 'description');
        if (desc && !init) {
          if (!this.validationColumnToView.colDesc || this.validationColumnToView.colDesc.indexOf(desc.settingValue) == -1) {
            this.validationColumnToView.colDesc = desc?.settingValue;
          }
        }
      }
      this.changeListenTyp({ value: this.validationColumnToView.colType });
    })

  }

  close() {
    switch (this.validationColumnToView.colType) {

      case (ColumnTypes.EAN):
        if (this.eanValue) this.validationColumnToView.validationRule = JSON.stringify(this.eanValue);
        break;
      case (ColumnTypes.Text):
        if (this.textValue) {
          this.validationColumnToView.validationRule = JSON.stringify(this.textValue);
          this.validationColumnToView.maxLength = this.textValue.maxlength;
          this.validationColumnToView.minLength = this.textValue.minlength;
          this.validationColumnToView.validRegEx = this.textValue.validregex;
        }
        break;
      case (ColumnTypes.DateRange):

        break;
      case (ColumnTypes.NumberRange):

        break;
      case (ColumnTypes.List):

      if (this.listValue) this.validationColumnToView.validationRule = JSON.stringify(this.listValue);
        if (this.validationColumnToView.id > 0) {
          this.store.dispatch(new UpdateStaticListValuesAction({
            columnlistid: this.validationColumnToView.id, items:
              this.listValue.valuelist.map(x => <ItemValueModel>{ label: x.item, value: x.item }), listtabletype: ItemValueListTypeTable.LISTCOLUMNS
          }));
        } else {
          this.validationListColumns$.subscribe(saved => {
            var col = this.validater.getClone(saved).sort((a,b) => b.id-a.id).find(x => x.colName == this.validationColumnToView.colName && x.colType == this.validationColumnToView.colType);
            if (col) {
              this.store.dispatch(new UpdateStaticListValuesAction({
                columnlistid: col.id, items:
                  this.listValue.valuelist.map(x => <ItemValueModel>{ label: x.item, value: x.item }), listtabletype: ItemValueListTypeTable.LISTCOLUMNS
              }));
            }
          }
          )
        }


        break;
      case (ColumnTypes.Number):
        if (this.numberValue) this.validationColumnToView.validationRule = JSON.stringify(this.numberValue);
        this.validationColumnToView.isNumberOnly = this.numberValue.isnumberonly ? 1 : 0;
        this.validationColumnToView.maxDigits = this.numberValue.maxdigits;
        this.validationColumnToView.maxDecimals = this.numberValue.maxdecimals;
        this.validationColumnToView.requiredColumnValue = this.numberValue.requiredcolumnvalue;

        break;
      case (ColumnTypes.CheckBox):
        if (this.checkBoxValue) this.validationColumnToView.validationRule = JSON.stringify(this.checkBoxValue);
        break;
      case (ColumnTypes.Date):
        if (this.dateValue) this.validationColumnToView.validationRule = JSON.stringify(this.dateValue);
        break;
      case (ColumnTypes.DynamicList):
        if (this.dynmlistValue) this.validationColumnToView.validationRule = JSON.stringify(this.dynmlistValue);
        this.validationColumnToView.listOptions = this.dynmlistValue.value;
        this.validationColumnToView.requiredColumnValue = this.referenceColumnValueDynList;
        break;

    }
    this.ref.close(this.validationColumnToView);
  }

  cancel() {
    this.ref.close();
  }

  deleteListValue(itemValue: string) {
    const idx = this.listValue.valuelist.findIndex(x => x.item == itemValue);
    this.listValue.valuelist.splice(idx, 1);
  }

  addNewListValue() {
    this.listValue.valuelist.push({ item: "Neuer Eintrag" });
  }
}
