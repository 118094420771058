import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, EventEmitter, WritableSignal, signal, AfterViewInit, computed, Signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { selectRowList, selectRowContentList, selectUploadData, selectRowHeaderList, selectSingleRowContent, selectValidationProfiles, selectErrorResult, selectValidationsbyUsedColumnId, selectContentListisLoading } from '../store/selectors';
import {
  GetListWithIdAction, SaveUploadListItemAction, UpdateRowStoreAction,
  InsertUploadListItemAction,
  CloseEditorAction, DeleteUploadRowsAction, FinishUploadAction, FinishUploadBySupplierAction, BubImportSelectedRowsAction, ListEditorActionType, FinishUploadByManagerDoneAction, CreateUploadAction, GetValidationsAction,
  GetUsedValidationListById,
  ClearUploadErrorAction,
  UpdateUploadMultiRowsAction
} from '../store';
import { map, filter, first, take, distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { RowContentModel, UploadFileModel, FileDescriptionModel, ActionStates, TaskTypes, GeneralResultEnum, UploadStaging, ValidationListColumn, LieferantTypeEnum } from '@app/common/models';
import { MessageService, ConfirmationService, MenuItem, SelectItem } from 'primeng/api';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { selectNavTabs } from '@app/store/navigation/selectors';
import { RemoveTabByNameAction } from '@app/store/navigation/actions';
import { ColumnValue, ColumnValuePrime } from '@app/common/models/columnValue.model';
import * as moment from 'moment';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { ListEditorService } from '@app/common/services/list-editor-service';
import { ValidationResults } from '@app/common/models/validResult.model';
import { SetTaskStateAction } from '@app/+userTasks/store';
import { AuthService } from '@app/common/services/auth.service';
import { IAuthUser, IClientError } from '@app/common/interfaces/Interfaces';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { ColumnTypes, DynListOption, ItemValueListTypeTable, ItemValueModel } from '@app/common/models/columnBase.model';
import { GetTaskbyIdAction, GetTaskbyIdActionDone, SetSingleTaskStateAction } from '@app/+task-list/store';
import { Table } from 'primeng/table';
import { UpdateArticleDataAction } from '@app/+articledata/store/actions';
import { selectArticleData } from '@app/+articledata/store/selectors';
import { NotificationService } from '@app/common/services/notification.service';
import { CategorySelection } from '@app/+articledata/pages/articledata-list/articledata-list.component';
import { LogUsersClientErrorAction } from '@app/store/user/actions';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TransferDialogComponent } from '../components/transfer-dialog/transfer-dialog.component';
import { Actions, ofType } from '@ngrx/effects';
import { CommonCheckService } from '@app/common/services/common.validation.service';

import { DeleteColumnConfig, GetColumnConfigs, InsertColumnConfig, UpdateColumnConfig, selectallKlammern, selectcolumnConfigs } from '@app/common-data-module/store';
import { selectSingleTaskbyId } from '@app/+task-list/store/selectors';
import { PTableChanges } from '@app/common/models/novi/noviColumns.model';
import { CategoryTaskSelection } from '@app/+task-list/task-list.component';
import { CustomSetting, CustomSettingsModulEnum, CustomSettingsTypeEnum } from '@app/common/models/custom.settings.models';
import { StoreColumnSetting } from '@app/common-data-module/models/storecolumnsetting';

import { LieferantenService } from '@app/common-data-module/services/LieferantenService';
import { UploadLieferantParam } from '@app/common-data-module/models/LieferantUserDTO';
import { MoebelService } from '@app/common-data-module/services/MoebelService';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { GetStaticListValuesAction, selectStaticUsedlistValues } from '@app/+validationEditor/store';
import { UpdateUploadParamClient, UpdateUploadParamServer } from '../models/updateparam';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

export class UploadChanges {
  old: any;
  changed; any;
}


export class DownLoadExcelFileParam {
  list: Array<ItemValueModel>;
  uploadId: number;
  rows: number[] = []
}

@Component({
  selector: 'app-list-edit',
  templateUrl: './list-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./list-editor.component.scss'],
  providers: [MessageService]
})

export class ListEditorComponent implements OnInit, AfterViewInit {

  @ViewChild('ptlist', { static: false }) listTable: Table;

  lStateKey = 'uploadeditor';
  //@ts-ignore

  selectSingleTask$ = this.store.pipe(select(selectSingleTaskbyId));
  //@ts-ignore
  allarticledataTasks$ = this.store.pipe(select(selectArticleData));
  //@ts-ignore
  navigationTabs$ = this.store.pipe(select(selectNavTabs));
  //@ts-ignore
  sortimentsKlammern$ = this.store.pipe(select(selectallKlammern));

  //@ts-ignore
  selectcolumnConfigs$ = this.store.pipe(select(selectcolumnConfigs));
  /**Spalten Config */
  columnconfig: WritableSignal<CustomSetting<StoreColumnSetting>> = signal(null);

  hasValidationColumn: WritableSignal<boolean> = signal(false);
  hasCheckColumn: WritableSignal<boolean> = signal(true);
  hasAllCheckBox: WritableSignal<boolean> = signal(false);
  selectallRows: WritableSignal<boolean> = signal(false);

  canExcelExportImport: WritableSignal<boolean> = signal(false);


  taskId: number;




  //@ts-ignore
  rowItems$ = this.store.pipe(select(selectRowList));
  //@ts-ignore
  uploadData$ = this.store.pipe(select(selectUploadData));

  minimum$ = this.uploadData$.pipe(map(x => x.minimum));
  maximum$ = this.uploadData$.pipe(map(x => x.maximum));

  isEditExpired$ = this.uploadData$.pipe(map(x => x.isEditExpired));
  isReadOnly$ = this.uploadData$.pipe(map(x => x.isEditExpired == 1 ? x.isEditExpired : x.isReadOnly));
  isReadOnlyMessage$ = this.uploadData$.pipe(map(x => x.isReadOnly == 1 ? x.isReadOnlyMessage : x.isEditExpiredMessage));

  //@ts-ignore
  contentList$ = this.store.pipe(select(selectRowContentList));

  //@ts-ignore
  contentListisLoading$ = this.store.pipe(select(selectContentListisLoading));

  //@ts-ignore
  headerList$ = this.store.pipe(select(selectRowHeaderList));
  //@ts-ignore
  validationProfiles$ = this.store.pipe(select(selectValidationProfiles));

  //@ts-ignore
  selectErrorResult$ = this.store.pipe(select(selectErrorResult));


  canEditCheckBox = computed(() => {
    if (this.contentListisLoading() == true)  return false;
    if (this.isReadOnly() == 1) return false;
    var result =  ( this.isRowsEditing() == true  )
    console.debug('canEditCheckBox', result);
    return result;
  })




  /**Fehler loggen z.B. Formatfehler */
  onError = new EventEmitter<string>();

  onRowChanged: Subject<PTableChanges> = new Subject<PTableChanges>();

  rowValuesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  rowValues$ = this.rowValuesSubject.asObservable();

  uploadExitSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  first = 0;
  rows= 10;

  isValid$ = this.contentList$.pipe(map(
    x => (x.filter(f => f.validationInfo).length === 0))
  );

  selectedRows: number[] = [];

  hasValidRowCount$ = this.contentList$.pipe(map(
    x => (this.minimum$.pipe(map(y => x.length >= y)) &&
      this.maximum$.pipe(map(m => x.length <= m)))
  ));

  lastChangeDate$ = this.uploadData$.pipe(map(x => x.changeDate));

  listName$ = combineLatest([this.uploadData$, this.validationProfiles$, this.selectSingleTask$]).pipe(map(([u, v, t]) => {
    if (u && u.usedValidationListName) return u.usedValidationListName;
    if (v && v.length > 0 && t && t.data) {
      return v.find(f => f.id == t.data.selectedValidationId)?.listName
    }
    return null;
  }));

  uploadId$ = this.uploadData$.pipe(map(x => x.id));
  validationName$ = combineLatest([this.uploadData$, this.selectSingleTask$]).pipe(map(([u, t]) => {
    if (u && u.attributeText) { return u.attributeText; }
    if (t && t.data) { return t.data.name };
    return "";
  }));
  hasSelectedForDelete$ = this.contentList$.pipe(map(x => x.filter(f => f.selected === true).length > 0));

  uploadServiceAddress = `${environment.connections.UploadStagingService}/UploadExcelFile?uploadId=`;
  mergeServiceAddress = `${environment.connections.UploadStagingService}/MergeExcelList?attributeId=`;

  clonedRows: { [s: string]: RowContentModel; } = {};

  frozenCols: any[];

  editItems: MenuItem[];
  subService: any;
  authUser: IAuthUser;
  isManager: boolean = false;
  taskType: TaskTypes; // task oder articledata
  filterCategories: CategorySelection | CategoryTaskSelection;
  //Es werden Zeilen bearbeitet
  isRowsEditing: WritableSignal<boolean> = signal(false);

  actionSubscription: Subscription;

  refTransfer: DynamicDialogRef;
  changes: { old: any; changed: any; }[];
  sortimentId: number;

  selectValidationsbyUsedColumnId$: Observable<{ [key: number]: ValidationListColumn }> = of({});
  dynOptions: WritableSignal<{ [key: number]: SelectItem[] }> = signal({});
  staticOptions: WritableSignal<{ [key: number]: SelectItem[] }> = signal({});

  selectStaticUsedlistValues$: Observable<{ [key: number]: ItemValueModel[]; }>;
  columnHeaders: WritableSignal<ColumnValuePrime[]> = signal([]);
  isReadOnly: Signal<number> = signal(1);
  contentListisLoading: Signal<boolean> = signal(false);
  isRowsEditing$: Observable<boolean> = of(false);





  constructor(private store: Store<any>, route: ActivatedRoute, private messageService: MessageService, private router: Router, private confirmationsService: ConfirmationService, private listeditService: ListEditorService,
    private cdRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private notifier: NotificationService,
    private httpClient: HttpClient,
    private commonCheckService: CommonCheckService,
    private dialogService: DialogService,
    public _actions$: Actions,
    private lieferantenService: LieferantenService,
    private moebelService: MoebelService
  ) {

    this.isRowsEditing$ = toObservable(this.isRowsEditing);
    this.contentListisLoading = toSignal(this.contentListisLoading$);
    this.isReadOnly = toSignal(this.isReadOnly$);
    this.store.dispatch(new GetTaskbyIdActionDone({ task: null }));

    this.filterCategories = this.router.getCurrentNavigation()?.extras?.state?.filterCategories as CategorySelection | CategoryTaskSelection; // params reverse aus listeditor
    this.taskId = route.snapshot.params.itemId;


    this.selectValidationsbyUsedColumnId$ = this.store.pipe(select(selectValidationsbyUsedColumnId));
    this.selectStaticUsedlistValues$ = this.store.pipe(select(selectStaticUsedlistValues));

    this.taskType = route.snapshot.params.type;

    this.authService.getLipoUser().subscribe(user => {
      this.authUser = user;
      if (environment.managers.some(x => user.member_of.includes(x))) {
        this.isManager = true;
      }
      //#6862
      this.store.dispatch(new GetColumnConfigs({ userid: this.authUser.id, modul: CustomSettingsModulEnum.UploadEditor, type: CustomSettingsTypeEnum.Columns }));


      // #6863
      switch (this.taskType) {
        case TaskTypes.Articledata:
          this.hasValidationColumn.set(this.isManager == false);
          this.hasCheckColumn.set(true);
          this.hasAllCheckBox.set(this.isManager == true);
          break;
        case TaskTypes.Task:
          this.hasValidationColumn.set(this.isManager == false);
          this.hasCheckColumn.set(this.isManager == false);
          break;

        default:
          this.hasValidationColumn.set(true);
          this.hasCheckColumn.set(true);
      }


      this.load();
    })

    this.selectErrorResult$.subscribe(err => {
      if (err && err.generalResult != GeneralResultEnum.Success) {

        /**Kann die snapshot Aufgabe mit manuellen Daten überschrieben werden   */
        this.selectSingleTask$.pipe(filter(f => f != undefined), take(1)).subscribe(task => {
          if (task != undefined && task.data != undefined
            && task.data.isEditExpired != 1
            && task.data.loadSnapshot == true) {
            this.confirmationService.confirm({
              message: 'Möchten Sie Input geben? ',
              header: 'Aktuell sind keine Titel im Sortiment enthalten.',
              acceptLabel: 'JA',
              rejectLabel: 'NEIN',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.addUploadManuell();
              },
              reject: () => {
                // nichts
              }
            })
          }
        })


        this.store.dispatch(new ClearUploadErrorAction());
      }
    })


  }
  ngAfterViewInit(): void {


  }


  /**formatieren des WErtes
   */
  getFormatedValue(colValue: ColumnValue, itemid: number): ColumnValue {
    var result = cloneDeep(colValue) as ColumnValue;
    if (result.columncelltype == 1) {
      if (result.value != undefined && result.value.length > 0 && !Number(result.value) && result.value.indexOf(',') > -1 && result.value.indexOf('.') == -1) {
        // Korrektur falscher Numerischer Werte
        //TODO: diese funktion könnte wieder entfernt werden, wenn sichergestellt ist, dass keinerlei falsche numerische Werte auf der DB existieren
        result.value = result.value.replace(',', '.');
        this.onError.emit(' ID ' + itemid.toString() + ' ' + colValue.columnname + ' ist im falschen Format  !');
      }
    }
    return result;
  }

  /**Einene lerreen Upload anglegen  wenn vom User gewollt*/
  addUploadManuell() {


    this.selectSingleTask$.subscribe(task => {

      const p: UploadStaging = {
        uploadParentTypes: 0,
        uploadingUserId: 0,
        isDeleted: false,
        name: task.data.name,
        id: 0,
        parentId: Number(this.taskId),
        changeDate: new Date(),
        createDate: new Date(),
        uploadComment: task.data.name,
        stagingDetails: [],
        uploadState: 10,
        usedValidationListId: task.data.selectedValidationId

      };

      this.store.dispatch(new CreateUploadAction({ uploadstaging: p, task: task }));





    }).unsubscribe()

  }



  /**Kan eine neue Zeile hinzugefügt werden */
  canNewRow(): Observable<boolean> {
    return combineLatest([this.uploadData$, this.selectSingleTask$, this.contentListisLoading$]).pipe(map(([upload, task, loading]) => {
      return !loading &&
        upload != undefined && upload.uploadState < 20 && task != undefined && task.data != undefined
        && task.data.isEditExpired != 1
        && task.data.taskState < ActionStates.Transfered_Lieferant

    }
    ));
  }

  /**CanFreigabe  **/
  canFreigabeAbschliessen(): Observable<boolean> {
    return combineLatest([this.isValid$, this.rowValues$, this.minimum$, this.maximum$]).pipe(map(([isvalid, rowValues, minimum, maximum]) => {
      return isvalid && (this.taskType != undefined &&
        (this.taskType == 'task'
          || (this.taskType == 'articledata' && !this.isManager && (rowValues.length > 0))))
        && ((rowValues.length >= minimum)
          && ((rowValues.length <= maximum || (maximum == 0))));
    }
    ))
  }

  /**Vorlage Export */
  canVorlageExport(): Observable<boolean> {
    return combineLatest([this.uploadData$, this.selectSingleTask$, this.contentListisLoading$, this.isRowsEditing$]).pipe(map(([upload, task, loading, edit]) => {
      return !loading &&
        ((task != undefined && task.data != undefined && task.data.taskState == ActionStates.TransferedNoInput) //noinput wird Liefeant angezeigt
          || upload != undefined && upload.uploadState < 20 && task != undefined && task.data != undefined && task.data.taskState < ActionStates.Transfered_Lieferant
        )
    }
    ));
  }

  /**Daten laden */
  canExcelHochladen(): Observable<boolean> {
    return combineLatest([this.isReadOnly$, this.uploadData$, this.selectSingleTask$, this.isRowsEditing$]).pipe(map(([readonly, upload, task, edit]) => {
      return edit == false && readonly != undefined && readonly != 1
        && upload != undefined && upload.uploadState < 20
        && task != undefined && task.data != undefined && task.data.isEditExpired != 1 && task.data.taskState < ActionStates.Transfered_Lieferant;
    }
    ));
  }


  /**Word das Aktions Menue angezeigt*/
  canLeftMenue(): Observable<boolean> {
    return combineLatest([this.uploadData$, this.selectSingleTask$]).pipe(map(([upload, task]) => {
      return this.isManager == false &&
        ((task != undefined && task.data != undefined && task.data.isEditExpired != 1 && task.data.taskState == ActionStates.TransferedNoInput) //noinput wird Liefeant angezeigt
          || upload != undefined && upload.uploadState < 20 && task != undefined && task.data != undefined && task.data.isEditExpired != 1 && task.data.taskState < ActionStates.Transfered_Lieferant
        )
        ;
    }
    ));
  }

  /**Kann der Foxxy Export durchgeführt werden
   * nur Manager und
   * upload == 15 und
   * tasktype == articledata
   */
  canFoxxyImportAsync(): Observable<boolean> {
    return combineLatest([this.uploadData$, this.rowValues$, this.selectSingleTask$]).pipe(map(([upload, rows, task]) => {
      return this.isManager == true &&
        this.taskType == TaskTypes.Articledata &&
        upload != undefined && upload.uploadState >= 15 &&
        rows! != undefined && rows.length > 0
        && task != undefined && task.data.taskState != ActionStates.Deleted_SOMA;
    }
    ))
  }

  foxxyImport(): void {
    this.allarticledataTasks$.pipe(take(1)).subscribe(tasks => {
      this.sortimentsKlammern$.pipe(take(1)).subscribe(klammern => {
        var task = tasks.filter(t => t.id == this.taskId)[0];
        if (task && task.taskState >= ActionStates.Transfered_Lieferant) {
          var klammer = klammern.find(f => f.id && task.klammerId && task.klammerId.endsWith(f.id.toString()));
          this.refTransfer = this.dialogService.open(TransferDialogComponent,
            {
              header: 'Artikeldaten in ZeFox übertragen',
              width: '64rem',
              height: 'auto',
              dismissableMask: false,
              closable: false,
              data: {
                taskId: this.taskId,
                taskName: task.name,
                userId: task.userId,
                userOrganisation: task.organization,
                klammer: klammer, // default
                klammerName: task.klammerName,
                selectedRows: this.selectedRows
              }
            });
        } else {
          this.messageService.add({ severity: 'warn', summary: 'Task für Übertragung konnte nicht geladen werden. Bitte neu laden.  ', detail: '' });
          if (this.refTransfer) this.refTransfer.close();
        }

      });
      this.actionSubscription = this._actions$.pipe(ofType<FinishUploadByManagerDoneAction>(ListEditorActionType.FinishUploadByManagerDone)).subscribe((data: any) => {
        this.messageService.add({ severity: 'success', summary: 'Die Artikeldaten wurden an Foxxy übergeben.', detail: '' });
        this.load();
        this.actionSubscription.unsubscribe();
        if (this.refTransfer) this.refTransfer.close();
      });
    })
  }



  getStaticModel(item, col: ColumnValue): SelectItem {
    if (this.staticOptions()[col.usedcolumnid] == undefined) return null;
    var result = this.staticOptions()[col.usedcolumnid].find(f => item.value && f.value == item.value);
    return result;
  }

  getDynModel(item, col: ColumnValue): SelectItem {
    if (this.dynOptions()[col.usedcolumnid] == undefined) return null;
    return this.dynOptions()[col.usedcolumnid].find(f => f.value == item.value);
  }



  createDynComboList(item, col: ColumnValue): void {

    if (this.dynOptions()[col.usedcolumnid] != undefined) return;
    if (item?.options == undefined) return;
    switch (item.options as DynListOption) {
      case DynListOption.MöbeldesSortimentsundModul:

        this.selectValidationsbyUsedColumnId$.pipe(filter(f => f == undefined || f[col.usedcolumnid] == undefined)).subscribe(d => {
          this.store.dispatch(new GetUsedValidationListById({ id: col.usedcolumnid }));
        })

        this.selectValidationsbyUsedColumnId$.subscribe(validations => {
          var defcol = validations[col.usedcolumnid];;
          if (defcol == undefined) { return; }
          var allcachedmobel = this.moebelService.moebelbySortiment();
          if (allcachedmobel[this.sortimentId]) {
            var modul = defcol.dbMapping.split('_')[3];
            var moebelbymodulparent = allcachedmobel[this.sortimentId].filter(f => modul != undefined && f.modul == Number(modul));
            var ch = (moebelbymodulparent) as any[];
            var moebelbymodulchildren = [];
            ch.forEach(f => {
              moebelbymodulchildren = [...moebelbymodulchildren, ...f.children ? f.children.filter(f => modul != undefined && f.modul == Number(modul)) : []];
            })
            moebelbymodulchildren = [...new Set(moebelbymodulchildren)];
            let moebelbymodul = [...moebelbymodulparent, ...moebelbymodulchildren];
            this.dynOptions.update(d => {
              d[col.usedcolumnid] = moebelbymodul.map(m => { return { label: m.name, value: m.id } });
              return { ...d };
            }
            )

          }
        })
        break;

      default:
        this.notifier.showError('Die Optionen für die Spalte konnten nicht geladen werden.');
        break;
    }


  }




  /**Spalten CSS setzen
   * möglichst 100% width
   *  dropdown seperat
   */
  gettdStyle(col: ColumnValue): string {
    var coltype = col.columncelltype as ColumnTypes;
    switch (coltype) {
      case ColumnTypes.Number:
      case ColumnTypes.Date:
      case ColumnTypes.Text:
        return "tdStyle100All";
    }
    return undefined;
  }

  /**Etwas verbesserte Breite der einzelnen Spalten, Platz Sparend !! */
  getColWidth(col: ColumnValue, defaultStyle: any): any {
    var obj = defaultStyle ?? {};
    switch (col.columnname.toLowerCase()) {
      case "rang":
      case "janein":
        obj['width'] = '100px';
        return obj;
      case "warengruppe":
        obj['width'] = '150px';
        return obj;
      case "ean":
        obj['width'] = '150px';
        return obj;
    }
    var coltype = col.columncelltype as ColumnTypes;
    switch (coltype) {
      case ColumnTypes.DynamicList:
        obj['width'] = '200px';
        return obj;
      case ColumnTypes.CheckBox:
        obj['width'] = '100px !important';
        return obj;
      case ColumnTypes.Number:
        obj['width'] = '120px';
        return obj;
      case ColumnTypes.Date:
        obj['width'] = '120px';
        return obj;
    }

    obj['width'] = '250px';
    return obj;
  }


  getFileFromReader(uploadFile: any): Promise<FileDescriptionModel> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const fileDesc: FileDescriptionModel = { id: null, fileName: '', fileType: '', fileBase64: '', initialLoad: false };
      reader.readAsDataURL(uploadFile);
      reader.onload = () => {
        fileDesc.fileName = uploadFile.name;
        fileDesc.fileType = uploadFile.type;
        fileDesc.fileBase64 = reader.result.toString().split(',')[1];
        resolve(fileDesc);
      };
    });
  }



  //TODO: SH prüfen ---- drop folder  https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/webkitGetAsEntry -<   <input id=b type=file webkitdirectory>
  // https://wicg.github.io/entries-api/#dom-datatransferitem-webkitgetasentry
  // https://github.com/primefaces/primeng/issues/1517
  // https://embed.plnkr.co/plunk/w8OpEK
  // https://github.com/ft115637850/ngx-folder-uploader
  async uploadImageFiles(event, fubAuto) {
    fubAuto.disabled = true;
    const newFile: UploadFileModel = {
      files: [],
      userId: 'HEADER'
    };
    for (const file of event.files) {
      var filecopy = cloneDeep(file);
      newFile.files.push(await this.getFileFromReader(filecopy));
    }
    this.uploadtoServer(newFile, fubAuto);
  }

  // TODO: SH nur test über Service direkt         --> store dispatch
  uploadtoServer(newFile: UploadFileModel, fubauto): void {
    try {
      var g = this.listeditService.UploadImagefiles(-1, newFile).subscribe(g => {
        fubauto.disabled = false;
        this.cdRef.detectChanges();


        if (g.userMessage) {

          this.messageService.add({ sticky: true, severity: 'error', summary: "Bilder wurden nicht hochgeladen", detail: g.userMessage });
          return;
        }

        var errors = 0;

        g.data.forEach(element => {
          if (element.validation != undefined && element.validation.result != ValidationResults.Success) {
            this.messageService.add({ severity: 'warn', summary: element.fileName + " wurde nicht hochgeladen", detail: element.validation.validationMessage });
            errors++;
          }
        });
        if (errors === 0) {
          this.messageService.add({ severity: 'success', summary: g.data.length > 1 ? g.data.length + ' Bilder wurden ' : 'Bild wurde ' + "erfolgreich hochgeladen", detail: '' });
        }
        this.cdRef.detectChanges();
      }, e => {
        this.messageService.add({ severity: 'error', summary: "Bilder wurden nicht hochgeladen", detail: e });
      })



    } catch (e) {

      fubauto.disabled = false;
      console.debug(e);
    }


  }

  /**Keine input Aktion
   * speichern des Tasks und setzen auf übermittelt ohne
   */
  closewithOutAction(): void {
    this.confirmationsService.confirm({
      message: "Aufgabe ohne Feedback abschließen ?",
      acceptLabel: 'OK',
      accept: () => {
        this.store.dispatch(new SetSingleTaskStateAction({ taskId: this.taskId, taskState: 550 }));
        this.store.dispatch(new SetTaskStateAction({ taskId: this.taskId, newTaskState: 550 }));
        if (this.isManager == true) {
          this.router.navigate(['/tasks']);
        } else {
          this.router.navigate(['/userTasks']);
        }
        if (this.columnconfig().id)
          {
            this.store.dispatch(new DeleteColumnConfig({ id: this.columnconfig().id }));
          }
      }
    });
  }

  closeList() {
    this.store.dispatch(new RemoveTabByNameAction(this.taskId.toString()));
    if (this.taskType == TaskTypes.Articledata) {
      const navigationExtras: NavigationExtras = { state: { filterCategories: <CategorySelection>this.filterCategories } };
      this.router.navigate(['/articledata'], navigationExtras);
    } else if (this.isManager == true) {
      const navigationExtras: NavigationExtras = { state: { filterCategories: <CategoryTaskSelection>this.filterCategories } };
      this.router.navigate(['/tasks'], navigationExtras);
    } else {
      this.router.navigate(['/userTasks']);
    }
  }

  load() {



    this.store.dispatch(new GetTaskbyIdAction({ taskid: this.taskId }));
    this.selectSingleTask$.pipe(filter(t => t != null), take(1)).subscribe(task => {

      let sortimentId = task.data.sortimentIds[0];
      this.sortimentId = sortimentId && sortimentId.toString().length > 5 ? Number(sortimentId.toString().substring(5, sortimentId.toString().length)) : Number(sortimentId);

      this.store.dispatch(new GetListWithIdAction({
        id: this.taskId,
        lieferantenparams: <UploadLieferantParam>{ lieferantenIds: [task.data.lieferantId], lieferantType: task.data.lieferantType }
      }));
      if (this.sortimentId) {
        this.moebelService.loadMoebel(this.sortimentId);
      }
      this.canExcelExportImport.set(true);
    })





    this.headerList$.pipe(filter(f => f.find(g => g && g.rowtype == 'header') != undefined), take(1)).subscribe(cols => {
      var header = cols.filter(y => y.rowtype == 'header')[0];
      if (header) {
        var result = cloneDeep(header.columnValues.map(m => new ColumnValuePrime(m)));

        this.columnHeaders.set(result);

        this.rowValues$.pipe(filter(f => f.length > 0), take(1)).subscribe(x => {
          var row = x[0];
          if (row) {
            this.columnHeaders().forEach(col => {
              if (col.columncelltype == ColumnTypes.DynamicList) {
                this.createDynComboList(row.cols[col.usedcolumnid], col);
              } else if (col.columncelltype == ColumnTypes.List) {
                this.createStaticComboList(row.cols[col.usedcolumnid], col);
              }
            })
          }
        })

        this.rowValues$.pipe(filter(f => f.length > 0), take(1)).subscribe(x => {

          this.selectSingleTask$.pipe(filter(f => f != undefined && f.data != undefined), take(1)).subscribe(up => {
            this.selectcolumnConfigs$.pipe(filter(f => f && f.length > 0 && f.find(c => c.name == up.data.name) != undefined), take(1)).subscribe(configs => {
              var savedconfig = configs.find(c => c.name == up.data.name);

              if (savedconfig && savedconfig.settingValue) {
                savedconfig = cloneDeep(savedconfig);
                savedconfig.data = JSON.parse(savedconfig.settingValue);
              }
              if (savedconfig && savedconfig.data) {

                if (this.columnHeaders().find(g => savedconfig.data.columnOrder.indexOf(g.field) == -1)) {
                  console.error('Spaltenkonfiguration nicht vollständig');
                  this.store.dispatch(new DeleteColumnConfig({ id: savedconfig.id }));
                } else {
                  this.columnconfig.set(savedconfig);
                  if (this.columnconfig()) {
                    savedconfig.settingValue = JSON.stringify(this.columnconfig()?.data);
                    sessionStorage.setItem(this.lStateKey, savedconfig.settingValue);
                    var setting = JSON.parse(this.columnconfig().settingValue);
                    if (setting && setting.columnOrder != undefined && setting.columnOrder.length > 0) {
                      result = result.sort((a, b) => setting.columnOrder.indexOf(a.field) - setting.columnOrder.indexOf(b.field));
                    }
                    this.columnHeaders.set(result);
                  }
                }
              }
            })
          })
        })

      }
    })

  }
  createStaticComboList(row: any, col: any) {

    if (this.staticOptions()[col.usedcolumnid] instanceof Array && this.staticOptions()[col.usedcolumnid].length > 0) return;

    this.selectValidationsbyUsedColumnId$.pipe(filter(f => f == undefined || f[col.usedcolumnid] == undefined), take(1)).subscribe(d => {
      this.store.dispatch(new GetStaticListValuesAction({
        columnlistid: col.usedcolumnid, listtabletype: ItemValueListTypeTable.USEDLISTCOLUMNS
      }));
    })

    this.selectStaticUsedlistValues$.subscribe(statics => {
      var defcol = statics[col.usedcolumnid];;
      this.staticOptions.update(d => {
        if (defcol instanceof Array) {
          d[col.usedcolumnid] = defcol.map(m => { return { label: m.value, value: m.value } });
        }
        if (row && row.value != undefined && (d[col.usedcolumnid] == undefined || !d[col.usedcolumnid].find(f => f.value == row.value))) {
          if (d[col.usedcolumnid] == undefined) {
            d[col.usedcolumnid] = [];
          }
          d[col.usedcolumnid].unshift({ label: row.value, value: row.value });
        }
        return { ...d };
      })


    })





  }

  getToolTip(colname: string, coldesc: string): string {
    let tipText: string = null;
    if (colname != undefined && colname.length > 17) {
      tipText = colname;
    }
    if (coldesc != undefined && coldesc.length > 0) {
      tipText = colname + ':\n' + coldesc;
    }
    return tipText;
  }

  /**Checkbox Readonly, wenn upload state > 10  */
  isCheckboxReadOnly(rowdata: RowContentModel): boolean {
    if (this.taskType == TaskTypes.Articledata && this.isManager == true) return false
    return true;
  }

  selectForActions(rowdata: RowContentModel) {

    if (rowdata.selected) {
      var rows = cloneDeep(this.selectedRows);
      if (rows.findIndex(f => f == rowdata.id)) rows.push(rowdata.id);
      this.selectedRows = rows;
    } else {
      const actId = this.selectedRows.findIndex(x => x === rowdata.id);
      var rows = cloneDeep(this.selectedRows);
      rows.splice(actId, 1);
      this.selectedRows = rows;
    }
  }

  selectallRowsForActions(e) {
    this.rowValues$.pipe(take(1)).subscribe(rows => {
      if (e.checked) {
        rows.forEach(row => {
          row.selected = true;
        })
        this.selectedRows = rows.map(m => m.id);
      } else {
        this.selectedRows = [];
        rows.forEach(row => {
          row.selected = false;
        })
      }
      this.rowValues$ = of(rows);
    })
  }


  deleteRows() {
    // this.messageService.add({severity: 'info', summary: 'Funktion noch nicht implementiert', detail: 'Löschen von Zeilen ist derzeit noch nicht verfügbar'});
    // return;
    if (this.selectedRows.length < 1) { return; }
    this.confirmationsService.confirm({
      message: (this.selectedRows.length == 1 ? 'Zeile wird gelöscht' : this.selectedRows.length + ' Zeilen werden gelöscht!'),
      acceptLabel: (this.selectedRows.length == 1 ? 'Zeile löschen' : 'Zeilen löschen'),
      rejectLabel: 'NEIN',
      accept: () => { this.deleteSelectedRows(); }
    });
  }

  testBoolean($event, value: string) {
    console.log($event);
  }

  getBooleanFromString(value: string) {
    return value == '1' ? true : false;
  }

  canCheckBoxSelection(): Observable<boolean> {
    if (this.isRowsEditing() == true) return of(false);
    return this.isReadOnly$.pipe(map(m => {
      if (this.taskType == TaskTypes.Articledata && this.isManager) return true;
      return m == undefined || m == 0;
    }));
  }

  /**is Checkbox visible */
  isUploadStateFinshedCheckBox(): Observable<boolean> {
    return this.isUploadStateOpenCheckBox().pipe(map(valid => !valid));
  }
  /**is Checkbox visible */
  isUploadStateOpenCheckBox(): Observable<boolean> {
    return this.uploadData$.pipe(map(m => {
      if (this.taskType == TaskTypes.Articledata && this.isManager) return true;
      return m.uploadState < 20;
    }));
  }



  getExportExcelTooltip(): string {
    if (this.isManager && this.taskType == TaskTypes.Articledata) return "Excel-Export der Artikeldaten";
    return "Export nach Excel";
  }



  importSelectedFromBub() {
    if (this.selectedRows.length < 1) { return; }
    this.confirmationsService.confirm({
      message: 'Die ausgewählten Artikeldaten werden durch Bub-Informationen ersetzt',
      acceptLabel: 'Daten importieren',
      accept: () => {
        const eans: string[] = [];
        for (let i = 0; i < this.selectedRows.length; i++) {
          let row: RowContentModel;
          const sub = this.store.pipe(select(selectSingleRowContent(this.selectedRows[i]))).subscribe(x => row = x);
          sub.unsubscribe();
          //try to find an ean field via columnname
          const eanValue = row.columnValues.find(x => x.columncelltype == ColumnTypes.EAN || x.columnname.toLowerCase() == 'ean');
          if (eanValue) eans.push(eanValue.value);
        }
        let uplId: number;
        const sub = this.uploadData$.subscribe(x => { uplId = x.id; });
        sub.unsubscribe();
        this.selectedRows = [];
        if (eans.length > 0) {
          this.store.dispatch(new BubImportSelectedRowsAction({ uploadId: uplId, eanList: eans }));
        }
      }
    });
  }

  exportnachExcel(event: MouseEvent): void {
    event.preventDefault();

    let fileName: string;

    let sub = this.selectSingleTask$.subscribe(x => { fileName = x.data.name; });
    sub.unsubscribe();

    let uploadId: number;
    sub = this.uploadData$.subscribe(x => { uploadId = x.id; });
    sub.unsubscribe();


    var allcachedmobel = this.moebelService.moebelbySortiment();
    var dynoptions = [];
    if (allcachedmobel[this.sortimentId]) {
      var moebelbymodulparent = allcachedmobel[this.sortimentId];
      var ch = (moebelbymodulparent) as any[];
      var moebelbymodulchildren = [];
      ch.forEach(f => {
        moebelbymodulchildren = [...moebelbymodulchildren, ...f.children ? f.children : []];
      })
      moebelbymodulchildren = [...new Set(moebelbymodulchildren)];
      dynoptions = [...moebelbymodulparent, ...moebelbymodulchildren];
    }


    var p = <DownLoadExcelFileParam>{ list: dynoptions.map(d => <ItemValueModel>{ label: d.name, value: d.id }), uploadId: uploadId, rows: this.selectedRows };
    let fileUrl = `${environment.connections.UploadStagingService}/DownLoadExcelFile`;


    this.httpClient.post(fileUrl, p, { responseType: "arraybuffer" }).subscribe({
      next: (x) => {
        const file = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        const url = window.URL.createObjectURL(file);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.target = "_self";
        a.href = url;
        a.download = "Export_" + this.commonCheckService.getValidFileName(fileName) + '.xlsx';
        a.click();
        a.remove();
      },
      error: (e) => this.downloadError(e)
    })
  }




  downloadError(e: any): void {
    if (e) {
      this.notifier.showError("Fehler beim Download.");
      console.debug(e);
    }
  }


  /**Können selektiere Rows erweitert werden um Bubdaten */
  canImportSelectedBubs(): Observable<boolean> {

    //nicht im editmodus
    if (this.isRowsEditing() != undefined && this.isRowsEditing() == true) return of(false);

    return combineLatest([this.rowValues$, this.isReadOnly$]).pipe(map(([rows, ronly]) => {
      if (ronly != undefined && ronly == 1) return false;
      if (rows == undefined) return false;
      var hasean = false;
      if (this.selectedRows != undefined && this.selectedRows.length > 0) {
        rows.filter(t => this.selectedRows.filter(r => r == t.id).length > 0).forEach(row => {
          var colkeys = Object.keys(row.cols);
          if (colkeys) {
            colkeys.forEach(key => {
              var col = row.cols[key];
              if (col) {
                if ((col.columnname.toLowerCase() == 'ean' || col.columncelltype == ColumnTypes.EAN) && this.commonCheckService.getEanError(col.value, 10).generalResult == GeneralResultEnum.Success) {
                  hasean = true;
                  return;
                }
              }
            });
          }
        });
      }
      return hasean;
    }
    ))
  }

  /**Können alle Bubinformationen übertagen werden */
  canImportAllBubs(): Observable<boolean> {

    if (this.isRowsEditing != undefined && this.isRowsEditing() == true) return of(false);
    return combineLatest([this.rowValues$, this.isReadOnly$]).pipe(map(([rows, ronly]) => {
      if (ronly != undefined && ronly == 1) return false;
      if (rows == undefined) return false;
      var hasean = false;
      rows.forEach(row => {
        var colkeys = Object.keys(row.cols);
        if (colkeys) {
          colkeys.forEach(key => {
            var col = row.cols[key];
            if (col) {
              if ((col.columnname.toLowerCase() == 'ean' || col.columncelltype == ColumnTypes.EAN) && this.commonCheckService.getEanError(col.value, 10).generalResult == GeneralResultEnum.Success) {
                hasean = true;
                return;
              }
            }
          });
        }
      });
      return hasean;
    }
    ))

  }

  importAllFromBub() {

    this.confirmationsService.confirm({
      message: 'Die Daten der selektierten Artikel werden ersetzt',
      acceptLabel: 'Daten importieren',
      accept: () => {
        const eans: string[] = [];
        let allRows: RowContentModel[];
        let sub = this.contentList$.subscribe(x => allRows = x);
        sub.unsubscribe();
        for (let i = 0; i < allRows.length; i++) {
          let row: RowContentModel;
          const sub = this.store.pipe(select(selectSingleRowContent(allRows[i].id))).subscribe(x => row = x);
          sub.unsubscribe();
          //try to find an ean field via columnname
          const eanValue = row.columnValues.find(x => x.columncelltype == ColumnTypes.EAN || x.columnname.toLowerCase() == 'ean');
          if (eanValue && eanValue.value) eans.push(eanValue.value);
        }
        let uplId: number;
        sub = this.uploadData$.subscribe(x => { uplId = x.id; });
        sub.unsubscribe();
        if (eans.length > 0) {
          this.store.dispatch(new BubImportSelectedRowsAction({ uploadId: uplId, eanList: eans }));
        }
      }
    });

  }

  private deleteSelectedRows() {
    if (this.selectedRows.length < 1) { return; }
    this.store.dispatch(new DeleteUploadRowsAction({ ids: this.selectedRows }));
    this.selectedRows = [];
  }

  finishUpload() {

    if (this.taskType == TaskTypes.Articledata) {
      var sub = this.allarticledataTasks$.subscribe(tasks => {
        var task = tasks.filter(t => t.id == this.taskId)[0];
        if (task && task.taskState != ActionStates.Transfered_Lieferant) {
          this.confirmationsService.confirm({
            header: 'Artikeldaten freigeben',
            message: 'Möchten Sie die Artikeldaten jetzt an Hugendubel übergeben?',
            acceptLabel: 'JA',
            rejectLabel: 'NEIN',
            accept: () => {

              if (!this.isManager) {
                let uploadid: number;
                const sub = this.uploadData$.subscribe(x => { uploadid = x.id; });
                sub.unsubscribe();
                this.store.dispatch(new FinishUploadBySupplierAction({ uploadId: uploadid }));
                var updatetask = { ...task };
                updatetask.taskState = ActionStates.Transfered_Lieferant;
                updatetask.articledatatransfered = new Date();
                this.store.dispatch(new UpdateArticleDataAction({ item: updatetask, successmessage: true }));
                this.store.dispatch(new CloseEditorAction());
                this.store.dispatch(new RemoveTabByNameAction(this.taskId.toString()));
                if (this.columnconfig().id)
                  {
                    this.store.dispatch(new DeleteColumnConfig({ id: this.columnconfig().id }));
                  }
              }
              const navigationExtras: NavigationExtras = { state: { filterCategories: <CategorySelection>this.filterCategories } };
              this.router.navigate(['/articledata'], navigationExtras);

            }
          });
        } else {
          this.notifier.showError("Artikeldaten zur Freigabe nicht gefunden.");
        }
      }

      )
      sub.unsubscribe();

    } else // normaler task
    {
      this.confirmationsService.confirm({
        header: 'Liste freigeben',
        message: 'Wollen Sie die Liste freigeben (eine weitere Bearbeitung ist dann nicht mehr möglich)?',
        acceptLabel: 'JA',
        rejectLabel: 'NEIN',
        accept: () => {
          let uploadid: number;
          const sub = this.uploadData$.subscribe(x => { uploadid = x.id; });
          sub.unsubscribe();

          this.store.dispatch(new FinishUploadAction({ uploadId: uploadid }));
          this.store.dispatch(new SetTaskStateAction({ taskId: this.taskId, newTaskState: 500 }));
          this.store.dispatch(new CloseEditorAction());
          this.store.dispatch(new RemoveTabByNameAction(this.taskId.toString()));
          this.router.navigate(['/userTasks']);
        }
      });
    }

  }

  /**Globalen Edit-modus setzen */
  setRowsEditing(edit: boolean): void {
    this.isRowsEditing.set(edit);
    if (this.isRowsEditing() == true) {
      this.selectedRows = [];
      var all = this.rowValuesSubject.getValue();
      all.forEach(row => {
        row.selected = false;
      });
      this.rowValuesSubject.next(all);
    }

  }

  addNewRow() {
    if (this.listTable) {
      this.listTable.first = 0;
    }
    // wenn Header verfügbar
    this.headerList$.pipe(filter(g => g != undefined && g.length > 0), take(1)).subscribe(h => {
      // neue Zeile anlegen neu implementiert wegen Mehrfachanlage


      var currentValues = this.rowValuesSubject.value ?? [];

      let newColumnValues: ColumnValue[] = [];
      const headerRow = h.filter(x => x.rowtype == 'header')[0];


      for (const item of headerRow.columnValues) {

        newColumnValues = [...newColumnValues, {
          usedcolumnid: item.usedcolumnid, columncelltype: item.columncelltype, columndesc: item.columndesc,
          columnname: item.columnname, value: null, items: item.items, ismandatory: item.ismandatory, datevalue: null,
          booleanValue: null, options: item.options
        }];

      }

      const newId = (((new Date().getTime() * 10000) + 621355968000000000) + (currentValues.length + 1)) * -1;

      var newvalue = {
        id: newId,
        selected: false,
        isValid: false,
        validationInfo: 'Neue Zeile - bitte bearbeiten um eine Prüfung der Daten durchzuführen',
        cols: newColumnValues.reduce((dic, kvp) => {
          dic[kvp.usedcolumnid] = this.getFormatedValue(kvp, newId);
          return dic;
        }, {})
      }

      this.rowValuesSubject.next([{ ...newvalue }, ...currentValues]);
      this.onRowChanged.next(<PTableChanges>{ data: newvalue });
      this.setRowsEditing(true);
    });

    this.selectedRows = [];
  }


  onEditInit() {
    this.setRowsEditing(true);
  }

  onTextChanged(e, row, col) {  // Textfeld geändert
    row.cols[col.usedcolumnid].value = e;
    this.onRowChanged.next(<PTableChanges>{ data: row });
  }

  onNumberChanged(e, row, col) {
    row.cols[col.usedcolumnid].value = e;
    this.onRowChanged.next(<PTableChanges>{ data: row });
  }

  getBooleanModel(cell) {
    if (cell.booleanValue && cell.booleanValue == true) return true;
    return false;
  }



  oncheckboxChanged(e, row, col) {
    var checked = e?.checked  ? e.checked : e;
    row.cols[col.usedcolumnid] = { ...row.cols[col.usedcolumnid] };
    row.cols[col.usedcolumnid].value = checked ? 1 : 0;
    row.cols[col.usedcolumnid].booleanValue = checked;
    this.onRowChanged.next(<PTableChanges>{ data: row });
  }

  onDateChanged(e, row, col) {
    row.cols[col.usedcolumnid] = { ...row.cols[col.usedcolumnid] };
    row.cols[col.usedcolumnid].value = e;
    row.cols[col.usedcolumnid].datevalue = e;
    this.onRowChanged.next(<PTableChanges>{ data: row });
  }


  ondyndropdownClear(event: DropdownChangeEvent, row, col: ColumnValue): void {
    try {
      if (row == undefined) return;
      row.cols[col.usedcolumnid].value = undefined;
      this.onRowChanged.next(<PTableChanges>{ data: row });
    } catch (e) {
      this.onError.emit('DynListe Clear Dropdown in uploadeditor ' + col.columnname + ' fails  -> ' + e.toString());
    }
  }


  dyndropdownChanged(event: DropdownChangeEvent, row, col: ColumnValue): void {
    try {
      if (row == undefined || event.value == undefined) return;
      row.cols[col.usedcolumnid].value = event.value.value.toString();
      this.onRowChanged.next(<PTableChanges>{ data: row });
    } catch (e) {
      this.onError.emit('DynListe Dropdownchanged in uploadeditor ' + col.columnname + ' fails  -> ' + e.toString());
    }
  }



  /**Dropdown changed */
  dropdownChanged(e, row, col): void {
    try {
      if (row == undefined) return;
      row.cols[col.usedcolumnid].value = e.value.value;
      this.onRowChanged.next(<PTableChanges>{ data: row });
    } catch (e) {
      this.onError.emit(' Dropdownchanged in uploadeditor ' + col.columnname + ' fails  -> ' + e.toString());
    }
  }

  onRowsEditSave() {
    try {
      this.isRowsEditing.set(false);
      var multiparam = new Array<UpdateUploadParamClient>();
      this.changes.forEach(change => {
        if (change.changed != undefined) {
          let rowid = change.changed.id;
          let rowcols = change.changed.cols;
          let valid = this.rowDataIsValid(change.changed);
          let uploadid = 0;
          let validName = '';
          const sub = this.uploadData$.subscribe(x => { uploadid = x.id; validName = x.attributeValidation; });
          sub.unsubscribe();
          if (rowid > 0) {
            multiparam.push({ id: rowid, cols: rowcols });
          } else {
            this.store.dispatch(new InsertUploadListItemAction({ uploadId: uploadid, oldId: rowid, validationName: validName, cols: rowcols, selectedRows: this.selectedRows }));
          }
        }
      })
      if (multiparam.length > 0) {
        this.store.dispatch(new UpdateUploadMultiRowsAction({ param: multiparam, selectedRows: this.selectedRows }));
      }



    } catch (e) {
      this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: this.authUser.id, modul: 'upload-editor Save', error: e } }));
    }
  }

  /**Vorformatierung z.b .EAN */
  formatValues(rowcols: {}): {} {
    try {
      var colkeys = Object.keys(rowcols);
      if (colkeys) {
        colkeys.forEach(key => {
          var col = rowcols[key];
          if (col) {
            if (col.columncelltype == 0 && col.value != undefined) {
              col.value = col.value.trim();
            } else if (col.columncelltype == 2) {
              col.value = col.booleanValue != undefined ? (col.booleanValue ? 1 : 0) : 0;
            }
          }
        });
      }
    } catch (e) {
      this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: this.authUser.id, modul: 'upload-editor', error: e } }));
    }
    return rowcols;
  }

  /**Deslect und Editiermodus komplett beenden
   * selected rows zurücksetzen
   */
  onRowsEditCancel() {


    var currentvals = this.rowValuesSubject.value;
    this.changes.forEach(change => {
      if (change.changed != undefined) {
        if (change.old != undefined) {
          let rowid = change.old.id;
          var id = currentvals.findIndex(f => f.id == rowid);
          if (id > -1)
          {
            currentvals[id] = cloneDeep(change.old );
          }
        } else if (change.changed.id < 0) {
          currentvals = currentvals.filter(t => t.id != change.changed.id);
        }
        change.changed = undefined;
      } else if (change.old != undefined && change.old.id < 0) {
        currentvals = currentvals.filter(t => t.id != change.old.id);
      }
    })

    this.rowValuesSubject.next(currentvals);
    this.setRowsEditing(false);

  }

  onBeforeUpload(event) {
    this.store.dispatch(new ClearUploadErrorAction());
  }

  onUploadFinished(event, fubAuto) {
    this.messageService.add({ severity: 'info', summary: ((event.files instanceof Array && event.files.length > 1) ? 'Ihre Dateien wurden hochgeladen' : 'Ihre Datei wurde hochgeladen'), detail: event.files[event.files.length-1] ?  event.files[event.files.length-1].name : '' });
    this.load();
    fubAuto.clear();
    this.uploadExitSubject.next(true);
  }

  uploadError(event, fubAuto) {
    this.messageService.add({
      severity: 'error', summary: 'Hochladen fehlgeschlagen', detail: (event.error?.error?.serverMessage ?? event.error?.error?.userMessage ?? 'Bitte versuchen Sie eine andere Datei'),
      sticky: true, closable: true
    });
    fubAuto.clear();
    this.store.dispatch(new ClearUploadErrorAction());
    this.uploadExitSubject.next(true);
  }

  onBeforeMergeUpload($event) {

    this.store.dispatch(new ClearUploadErrorAction());
  }

  onMergeUploadFinished($event, fubmerge) {
    this.messageService.add({ severity: 'info', summary: 'Ihre Datei wurde verarbeitet', detail: $event.files[0].name });
    this.load();
    fubmerge.clear();
    this.uploadExitSubject.next(true);
  }

  uploadMergeError($event, fubmerge) {
    var servermess = ($event.error != undefined && $event.error?.error?.serverMessage != undefined) ? $event.error.error.serverMessage : $event.error?.error?.userMessage;
    this.messageService.add({
      severity: 'error', summary: 'Verarbeiten der Datei fehlgeschlagen', detail: servermess,
      sticky: true, closable: true
    });
    fubmerge.clear();
    this.uploadExitSubject.next(true);

  }

  rowDataIsValid(rowData): boolean {
    if (rowData.validationInfo == null) return true;
    if (rowData.validationInfo.length < 1) return true;
    return false;
  }

  getDateFromString(dateString: string): Date {
    if (!dateString) return null;
    const datum = moment(dateString, 'DD.MM.YYYY HH:mm:ss')
    if (datum.isValid()) return datum.toDate();
    return null;

  }

  downloadListForTask(event: MouseEvent) {
    event.preventDefault();
    this.selectSingleTask$.pipe(filter(g => g != undefined), take(1)).subscribe(task => {
      if (!task || !task.data) {
        this.notifier.showError("Aufgabe konnte nicht geladen werden");
        return;
      }
      const fileUrl = `${environment.connections.ValidationEditService}` + '/Validation/GetValidationList?taskId=' + this.taskId;
      this.httpClient.get(fileUrl, { responseType: "arraybuffer" }).subscribe(x => {
        const file = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        const url = window.URL.createObjectURL(file);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.target = "_self";
        a.href = url;
        a.download = "Vorlage_" + this.commonCheckService.getValidFileName(task.data.selectedValidation) + '.xlsx';
        a.click();
        a.remove();
      });
    });

  }

  ngOnInit() {


    // initial entfernen
    sessionStorage.removeItem(this.lStateKey);
    this.contentList$.pipe(distinctUntilChanged()).subscribe(data => {
      try {
        // überflüssig var content = data.filter(x => x.rowtype == 'content');
        var result = data.map(item => ({
          id: item.id,
          selected: this.selectedRows.findIndex(f => f == item.id) >= 0,
          isValid: item.isValid,
          validationInfo: item.validationInfo,
          cols: item.columnValues.reduce((dic, kvp) => {
            dic[kvp.usedcolumnid] = this.getFormatedValue(kvp, item.id);
            return dic;
          }, {})
        }));

        this.rowValuesSubject.next(result);
        this.changes = result.map(m => { return { old: cloneDeep(m), changed: undefined } });

      } catch (e) {
        this.onError.emit('UploadEditor Init fails  -> ' + e.toString());
      }
    }
    )

    //Message clear / Upload beendet
    this.uploadExitSubject.pipe(debounceTime(1000), take(1)).subscribe(e => {
      if (e == true) {
        this.messageService.clear();
        this.uploadExitSubject.next(false);
      }
    })


    this.store.dispatch(new GetValidationsAction());
    //Den ersten Fehler loggen
    this.onError.pipe((first())).subscribe(e => {
      this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: this.authUser.id, modul: 'upload-staging', error: e } }));
    })

    this.frozenCols = [
      { field: 'id', header: 'Aktion' },
    ];

    this.editItems = [
      { label: 'Markierte Abgleichen' },
      { label: 'Alle Abgleichen' },
      { label: 'Markierte Löschen' }
    ];



    /**Row der Tabelle hat sich geändert
  * Vorsicht: bei Klick auf Save kann es sein, das dieses Event noch nicht ausgeführt wurde
 */
    this.onRowChanged.subscribe((e: PTableChanges) => {
      try {
        if (!e.data) {
          throw new Error('RowChanged Event ohne Daten');
        }
        var cloned = cloneDeep(e.data);
        var idx = this.changes.findIndex(f => e.data.id > 0 && f.old && f.old.id == e.data.id);
        if (idx > -1) {
          this.changes[idx].changed = cloned;
        } else {
          var idxnew = this.changes.findIndex(f => f.changed && f.changed.id == e.data.id);
          if (idxnew > -1) {
            this.changes[idxnew].changed = cloned;
          } else {
            this.changes.push({ old: undefined, changed: cloned });
          }
        }




      } catch (e) {
        this.onError.emit('RowChanged Event in uploadeditor fails  -> ' + e.toString());
      }
    })

  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.store.dispatch(new CloseEditorAction());
    if (this.columnconfig()) {
      this.notifier.snackBar.dismiss();
      var config = cloneDeep(this.columnconfig());
      if (config.id > 0) {
        config.lastAccess = 1;
        this.store.dispatch(new UpdateColumnConfig({ customsetting: config }));
      } else {
        config.lastAccess = 1;
        this.store.dispatch(new InsertColumnConfig({ customsetting: config }));
      }
    }
    sessionStorage.removeItem(this.lStateKey);
  }



  /**State der Table speichern */
  onStateSave(e) {

    var setting = e as StoreColumnSetting;
    this.selectSingleTask$.pipe(filter(f => f != undefined && f.data != undefined), take(1)).subscribe(up => {
      this.columnHeaders.update(d => {
        var p = <CustomSetting<StoreColumnSetting>>
          {
            id: this.columnconfig()?.id ?? -1,
            userId: this.authUser.id,
            modul: CustomSettingsModulEnum.UploadEditor,
            type: CustomSettingsTypeEnum.Columns,
            name: up.data.name,
            lastAccess: 0,
            settingValue: JSON.stringify(setting),
            objectType: 'ColumnSelection',
            data: setting
          }
        d = cloneDeep(d);
        if (setting.columnOrder != undefined && setting.columnOrder.length > 0) {
          d = d.sort((a, b) => setting.columnOrder.indexOf(a.field) - setting.columnOrder.indexOf(b.field));
        }
        this.columnconfig.set(p);
        return [...d];
      })
    })
  }


}


